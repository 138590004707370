import Home from "./components/home.vue";
export default {
  name: "App",
  components: {
    Home
  },
  data() {
    return {};
  },
  mounted() {
    if (this.isMobile()) {
      let protocol = window.location.protocol;
      let host = window.location.host;
      let baseUrl = protocol + "//" + host;
      window.location.href = baseUrl + "/m";
    } else {
      // console.log('PC', host())
    }
  },
  methods: {
    isMobile() {
      console.log(navigator.userAgent);
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  }
};
export default {
  name: "Home_tiaoti",
  props: {
    msg: String
  },
  mounted() {
    setTimeout(() => {
      this.showOne = true;
      this.showTwo = true;
    }, 500);
    setTimeout(() => {
      this.showThree = true;
    }, 1500);
    setTimeout(() => {
      this.showFour = true;
    }, 2200);
  },
  data() {
    return {
      active: 0,
      active_tag: 0,
      show: false,
      showOne: false,
      showTwo: false,
      showThree: false,
      showFour: false,
      showOne_gai: false,
      showTwo_gai: false,
      showThree_gai: false,
      showFour_gai: false,
      showOne_purple: false,
      showTwo_purple: false,
      showThree_purple: false,
      showFour_purple: false,
      showOne_green: false,
      showTwo_green: false,
      showThree_green: false,
      showFour_green: false,
      showOne_tie: false,
      showTwo_tie: false,
      showThree_tie: false,
      showFour_tie: false,
      showOne_end: false,
      showTwo_end: false,
      showThree_end: false,
      tagArr: [{
        name: "首页"
      }, {
        name: "小/大蓝钙"
      }, {
        name: "小紫维"
      }, {
        name: "小绿叶"
      }, {
        name: "大乳铁"
      }, {
        name: "品牌故事"
      }]
    };
  },
  methods: {
    clipGaiShop() {
      window.location.href = 'tmall://tmallclient/?{"action":"item:id=725134504324"}';
    },
    clipZiweiShop() {
      window.location.href = 'tmall://tmallclient/?{"action":"item:id=725708675502"}';
      // window.location.href = 'taobao://m.tb.cn/h.5dHDpFi?tk=4mK7dDYiydy';
    },

    clipGreenShop() {
      window.location.href = 'tmall://tmallclient/?{"action":"item:id=725695144699"}';
      // window.location.href = 'taobao://m.tb.cn/h.5dHxpjC?tk=2iEtdDYRv9B';
    },

    clipTieShop() {
      window.location.href = 'tmall://tmallclient/?{"action":"item:id=725673356754"}';
      // window.location.href = 'taobao://m.tb.cn/h.5dHy6oj?tk=lc2FdDYjR9Q';
    },

    onChange(index) {
      this.hideBoolValue();
      this.active = index;
      this.active_tag = index;
      switch (index) {
        case 0:
          setTimeout(() => {
            this.showOne = true;
            this.showTwo = true;
          }, 500);
          setTimeout(() => {
            this.showThree = true;
          }, 1500);
          setTimeout(() => {
            this.showFour = true;
          }, 2200);
          break;
        case 1:
          setTimeout(() => {
            this.showOne_gai = true;
            this.showTwo_gai = true;
          }, 500);
          setTimeout(() => {
            this.showThree_gai = true;
          }, 1500);
          setTimeout(() => {
            this.showFour_gai = true;
          }, 2200);
          break;
        case 2:
          setTimeout(() => {
            this.showOne_purple = true;
            this.showTwo_purple = true;
          }, 500);
          setTimeout(() => {
            this.showThree_purple = true;
          }, 1500);
          setTimeout(() => {
            this.showFour_purple = true;
          }, 2200);
          break;
        case 3:
          setTimeout(() => {
            this.showOne_green = true;
            this.showTwo_green = true;
          }, 500);
          setTimeout(() => {
            this.showThree_green = true;
          }, 1500);
          setTimeout(() => {
            this.showFour_green = true;
          }, 2200);
          break;
        case 4:
          setTimeout(() => {
            this.showOne_tie = true;
            this.showTwo_tie = true;
          }, 500);
          setTimeout(() => {
            this.showThree_tie = true;
          }, 1500);
          setTimeout(() => {
            this.showFour_tie = true;
          }, 2200);
          break;
        case 5:
          setTimeout(() => {
            this.showOne_end = true;
            this.showTwo_end = true;
          }, 500);
          setTimeout(() => {
            this.showThree_end = true;
          }, 1800);
          break;
        default:
          break;
      }
    },
    changeStart(index) {
      console.log(index);
    },
    hideBoolValue() {
      this.showOne = false;
      this.showTwo = false;
      this.showThree = false;
      this.showFour = false;
      this.showOne_gai = false;
      this.showTwo_gai = false;
      this.showThree_gai = false;
      this.showFour_gai = false;
      this.showOne_purple = false;
      this.showTwo_purple = false;
      this.showThree_purple = false;
      this.showFour_purple = false;
      this.showOne_green = false;
      this.showTwo_green = false;
      this.showThree_green = false;
      this.showFour_green = false;
      this.showOne_tie = false;
      this.showTwo_tie = false;
      this.showThree_tie = false;
      this.showFour_tie = false;
      this.showOne_end = false;
      this.showTwo_end = false;
      this.showThree_end = false;
    },
    tagChange(index) {
      this.active_tag = index;
      this.$refs.swipe.swipeTo(index);
    },
    showRightView() {
      this.show = true;
    }
  }
};
import { createApp } from 'vue'
import App from './App.vue'
import Vue from 'vue';
import 'lib-flexible'
import 'lib-flexible/flexible.js'
import animate from 'animate.css'
import VueRouter from 'vue-router';


const app = createApp(App);
app.use(VueRouter);
app.use(Vue);
app.use(animate)
app.mount('#app')